@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: "SUSE", sans-serif !important;
  scroll-behavior: smooth;
}

text {
  font-family: "SUSE", sans-serif !important;
}

.suse {
  font-family: "SUSE", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-invisible::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-invisible {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.text-8xl {
  font-size: 6rem;
  /* 96px */
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem;
  /* 72px */
  line-height: 1;
}

.text-5xl {
  font-size: 3rem;
  /* 48px */
  line-height: 1;
}

.text-3xl {
  font-size: 1.875rem;
  /* 30px */
  line-height: 2.25rem;
  /* 36px */
}